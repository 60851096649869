/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
p[data-v-2104b1ae] {
  all: revert;
}
.confirm-delete-wrapper[data-v-2104b1ae] {
  padding: 2rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.confirm-delete-wrapper .header[data-v-2104b1ae] {
  text-align: center;
}
.confirm-delete-wrapper .body[data-v-2104b1ae] {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.confirm-delete-wrapper .body .form-control[data-v-2104b1ae] {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.confirm-delete-wrapper .body label[data-v-2104b1ae],
.confirm-delete-wrapper .body input[data-v-2104b1ae] {
  cursor: pointer;
}
.confirm-delete-wrapper .body input[data-v-2104b1ae] {
  padding: 1rem;
  border: 2px solid #ccc;
  outline: none;
}
.confirm-delete-wrapper .body input[data-v-2104b1ae]:focus {
  border-color: #e93f6f;
}
.confirm-delete-wrapper .body .text-danger[data-v-2104b1ae] {
  color: #d2222d;
}
.confirm-delete-wrapper .footer[data-v-2104b1ae] {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.confirm-delete-wrapper .footer .discard[data-v-2104b1ae] {
  margin-right: 2rem;
}
.confirm-delete-wrapper .footer .discard.btn--default[data-v-2104b1ae] {
  background-color: #ebebeb;
  color: black;
}
.confirm-delete-wrapper .footer .disabled[data-v-2104b1ae] {
  opacity: 0.2;
  cursor: not-allowed;
}